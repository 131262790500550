@import '../../styles/variables';
@import '../../styles/mixin';

.counter__weight_variant_title_weights {
  margin: 0 0 20px;
  min-width: 206px;
  font-weight: 700;
  font-size: $text-xl !important;
  line-height: 24px;
  color: $gray-600 !important;
  display: none;

  @include media(lg){
     display: block;
  }
}

.counter__weight_variant_container {
  min-width: 330px;
  display: inline-flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 8px;
  margin-bottom: 24px;

  @media screen and (max-width: 420px) {
    min-width: 270px;
  }

  @media screen and (min-width: 500px) {
    min-width: 410px;
  }

  @media screen and (min-width: 590px) {
    min-width: 500px;
  }

  @include media(sm) {
    min-width: 540px;
  }

  @include media(md) {
    min-width: 280px;
  }

  @include media(lg) {
    min-width: 360px;
  }

  @include media(xl) {
    min-width: 400px;
  }

  @include media(3xl) {
    min-width: 507px;
  }

  .counter__weight_variant_btn {
    font-size: $text-xs;
    line-height: 1;
    //height: 36px;
    padding: 10px 24px;
    border: 1px $detail-weight-variant-btn-border-type
      $detail-weight-variant-btn-border;
    color: $detail-weight-variant-btn-text-color;
    background-color: $detail-weight-variant-btn-bg-color;
    position: relative;
    overflow: visible;

    &:hover {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-hover-border;
      color: $detail-weight-variant-btn-hover-text-color;
      background-color: $detail-weight-variant-btn-hover-bg-color;
    }

    &_active {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-active-border;
      color: $detail-weight-variant-btn-active-text-color;
      background-color: $detail-weight-variant-btn-active-bg-color;
      opacity: 1 !important;
    }

    @include media(lg) {
      font-size: $text-sm;
    }
  }

  .counter__btn-responsibe {
    flex-grow: 1;
    min-width: 150px;
  }

  .weight_variant_btn {
    padding: 8px 17px;
    border-radius: $detail-weight-variant-btn-border-radius;
    min-width: 70px;
    font-size: $text-xs;
    border: 1px solid transparent;
    color: $detail-weight-variant-btn-text-color;
    background-color: $detail-weight-variant-btn-bg-color;
    height: auto;

    &:hover {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-hover-border;
      color: $detail-weight-variant-btn-hover-text-color;
      background-color: $detail-weight-variant-btn-hover-bg-color;

      .weight_variant_btn_content {
        .weight_variant_btn_price {
          color: $detail-weight-variant-btn-hover-info-color;
        }
      }
    }

    &_active {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-active-border;
      color: $detail-weight-variant-btn-active-text-color;
      background-color: $detail-weight-variant-btn-active-bg-color;
      opacity: 1 !important;
    }

    .weight_variant_btn_content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .weight_variant_btn_label {
        color: $detail-weight-variant-btn-text-color;
        font-size: $text-xs;
        font-weight: 500;
      }

      .weight_variant_btn_price_container {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;

        .weight_variant_btn_price {
          color: $detail-weight-variant-btn-info-color;
          font-size: $text-xl;
          font-weight: 700;

          &_active {
            color: $detail-weight-variant-btn-active-info-color;
          }
        }

        .weight_variant_btn_discount {
          color: $detail-weight-variant-btn-text-color;
          font-size: $text-xs;
          font-weight: 500;
          text-decoration: line-through;
          height: 100%;
        }
      }
    }
  }
}
